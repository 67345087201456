<template>
    <div>
            
        <h1>Profile</h1>
        <h2>{{$t('message')}}</h2>
        <Lang/>
        <HelloWorld/>
    </div>
</template>
<script>
import Lang from '@/components/SwitchLangauge.vue'
export default {
    name: 'Profile',
    components: {
        Lang,
        HelloWorld: ()=> import('@/components/HelloWorld')
    }
}
</script>